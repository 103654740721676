.container {
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  grid-row: 3/5;
}

.container .navigateBack {
  top: 0.5em;
  left: -2.5em;
}

.container .navigateBack .button {
  position: fixed;
}

.container .main {
  position: relative;
  display: flex;
  flex-direction: row;
}
