.images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4em;
}

.selected {
  opacity: 1;
  filter: grayscale(0);
}

.unselected {
  opacity: 0.5;
  filter: grayscale(1) blur(1px);
}

.check {
  color: green;
}
