.container .left {
  display: flex;
  align-items: center;
}

.container .left > :not(:first-child) {
  margin-left: 0.5rem;
}

.container .logo {
  height: 36px;
}

.container .toolbar {
  display: flex;
  justify-content: space-between;
}

.container .links button {
  color: inherit;
  background-color: inherit;
  border-color: inherit;
  margin-right: 1em;
  border-radius: 20px;
  border-width: 2px;
}

.container .links button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: auto;
}

.user {
  display: flex;
  align-items: center;
}


