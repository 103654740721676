.card {
  width: 200px;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
}

.smallCard {
  height: 350px;
}

.cardBody {
  flex-grow: 1;
  overflow: hidden;
}

.mediaWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.mediaWrapper.small {
  height: 160px;
}

.buttonWrapper {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.mediaZoomIn {
  cursor: zoom-in;
}

.media.small {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.media.large {
  max-height: calc(100vh - 8em);
  object-fit: contain;
  cursor: zoom-out;
  max-width: 100%;
}

.media:global(.MuiCircularProgress-root) {
  animation-duration: 5s;
}
