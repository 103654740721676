.right {
  padding: 0.5em;
  width: 34em;
  margin-left: 0.5em;
  overflow: auto;
  height: 93vh;
}

.right > :global(.paper):not(:last-child) {
  margin-bottom: 2em;
}
