.left {
  padding: 0.5em;
  padding-top: 1em;
  flex-basis: 25em;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  height: 93vh;
}

.left > :global(.paper):not(:last-child) {
  margin-bottom: 2em;
}
