.videoForm {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.descriptionText {
  width: 100%;
}

.formSection {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.formSectionTitle {
  margin-left: 1em;
  width: 5.5em;
}
