.modalContainer:global(.MuiGrid-container) {
  height: 100%;
}

.modalContent {
  width: 40vw;
  overflow: auto;
  padding: 1em;
}

.select {
  margin-bottom: 1em;
}

.buttonGroup {
  padding-top: 1em;
}

.buttonGroup .modalButton {
  float: right;
  margin-left: 0.5em;
}
