.card {
  padding: 0.5em;
  width: 100%;
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.cardContainer {
  display: flex;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.columnLeft {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.columnRight {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

.media {
  width: 75px;
}

.photographerContainer {
  margin-top: auto; /* Pushes the name to the bottom of the column */
}

.photographer {
  color: gray;
  font-size: 0.8em;
  font-weight: 400;
  padding-top: 10px;
}

.link {
  text-decoration: none;
}

.mainDescription {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 5px;
}

.secondaryDescription {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  font-size: 0.7em;
}
