.container {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
}

.searchBar {
  width: 100%;
  margin-top: -16px;
}

.searchBar .wrapper {
  position: relative;
}

.searchBar .loading {
  position: absolute;
  margin-left: 38px;
}

@media (max-width: 45em) {
  .container {
    flex-direction: column;
  }

  .searchBar {
    align-self: flex-end;
  }
}
