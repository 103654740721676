.lane {
  width: 22em;
  padding: 0.5em 1em 0.5em;
}

.lane.collapsed {
  width: 3em;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.header.collapsed {
  position: relative;
  padding-top: 1px;
  padding-bottom: 0.5em;
  writing-mode: vertical-lr;
  width: 1em;
}

.button {
  margin-right: 0.2em;
  background-color: transparent !important;
}

.button.expanded {
  margin-left: -0.45em;
}
