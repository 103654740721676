.container:global(.MuiStepper-root) {
  padding: 0;
}

.container :global(.MuiStepConnector-lineVertical) {
  min-height: 0.5em;
}

.container .button {
  margin-top: 1em;
}
