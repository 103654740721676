.container {
  display: flex;
  flex-direction: column;
  height: 95%;
}

.table {
  flex-grow: 1;
  flex-shrink: 1;
}

.table .tableHead {
  position: sticky;
  border: 0;
  top: 0;
  z-index: 1;
}

.table .tableHead:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid;
}

.buttons {
  float: right;
}

.newTag {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-top: 1em;
}
