.container {
  width: 80vw;
  margin: auto;
}

.heading {
  padding-left: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headingWithIcon {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  align-items: center;
}

.body {
  padding: 1em 1em 1em 1em;
  margin-bottom: 1em;
  display: flex;
  height: 70vh;
}

.tabs {
  margin-right: 2em;
  border-right: 1px solid #ccc;
  min-width: 11em;
}

.tab {
  width: 10em;
}

.tabPane {
  flex-grow: 1;
  height: 100%;
  padding-bottom: 2.5em;
}
