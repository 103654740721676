.container {
  width: 100%;
  height: 100%;
}

.flair {
  border-left: 0.3em solid;
}

.contextual-photo {
  border-color: lightblue;
}

.installation-photo {
  border-color: lightcoral;
}

.single-product {
  border-color: cadetblue;
}

.product-collection {
  border-color: mediumpurple;
}

.dining-set {
  border-color: burlywood;
}

.retouch {
  border-color: darkseagreen;
}
