.container {
  padding-top: 0;
  margin-top: 1.5em;
  width: 80vw;
  height: 80vh;
}

.content {
  display: flex;
  min-height: 60vh;
}

.imageGrid {
  margin: 0;
  flex-basis: 100%;
}

.imageGrid.compact {
  flex-basis: calc(100% - 20em);
}

.card {
  position: relative;
  cursor: pointer;
}

.card.locked {
  cursor: no-drop;
}

.card .locked {
  position: absolute;
  top: 0.7em;
  right: 0.1em;
}

.loadMoreContainer {
  width: 200px;
  height: 282px;
}

.filterPanel {
  height: 60vh;
  flex-basis: calc(20em);
  position: sticky;
  top: 6em;
  padding-top: 0.5em;
  overflow-y: auto;
}
