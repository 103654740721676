.root {
  align-items: center;
  border-radius: 4px;
  display: inline-grid;
  font-size: 14px;
  gap: 8px;
  grid-template-columns: auto auto;
  padding: 4px 25px;
  pointer-events: none;
}

.rootLight {
  background-color: rgba(51, 51, 51, 0.08);
  box-shadow: 0 12px 24px -6px rgba(0, 0, 0, .25), 0 0 0 1px rgba(0, 0, 0, .08);
  color: #000;
  border: 1px solid darkgrey;
}

.rootDark {
  background-color: black;
  box-shadow: 0 12px 24px -6px rgba(0, 0, 0, .25), 0 0 0 1px rgba(0, 0, 0, .08);
  color: #fff;
  border: 1px solid darkgrey;
}

.icon, .label {
  align-items: center;
  display: flex;
}
