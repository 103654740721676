.fieldset :global(.MuiTypography-body1) {
  font-size: small;
}

.legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
}

.legend > label {
  margin-left: 0.3em;
}

.legend :global(.MuiButtonBase-root) {
  padding: 0 0.1em;
}
