.previewImage {
  max-height: 2em;
  margin: -6px 0;
}

.filler {
  flex-grow: 1;
}

.tooltip {
  width: 100%;
}

.fileName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 1em;
  flex-grow: 1;
}
.titleFlexFix {
  /* Prevent the flexbox from overflowing the container in the title */
  min-width: 0;
}

.editButton {
  transform: scale(0.8);
  padding: 0;
  margin: 0 0.5em;
}
