.root {
  display: flex;
  flex-direction: column;
}

:root {
  --global-sidebar-width: 240px;
}

.container {
  display: flex;
  height: 100%;
  min-height: 100vh;
}

main {
  flex-grow: 1;
  display: grid;
  grid-template-rows:
    48px
    0em
    calc(100vh - 48px - 0em);
  grid-template-columns:
    0px
    auto
    0px;
}

main > * {
  grid-row: 3;
  grid-column: 2;
}

footer {
  flex-shrink: 0;
}