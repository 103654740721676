.root {
  align-items: center;
  display: flex;
  grid-template-columns: auto auto 1fr auto;
  height: 2.5em;
  padding-inline-end: 8px;
  justify-content: space-between;
  cursor: pointer;
}

.draggable {
  cursor: move;
}

.root.isDisabled {
  color: rgba(255, 255, 255, 0.3);
}

.rootDark {}
.rootDark.isSelected {
  background-color: rgba(144, 202, 249, 0.16);
}

.rootLight {}
.rootLight.isSelected {
  background-color: rgba(51, 51, 51, 0.08);
}

.rootDark:hover {
  background-color: rgba(37, 37 ,37 ,255);
}

.rootLight:hover {
  background-color: rgba(51, 51, 51, 0.04);
}

.clipbookName {
  font-size: 0.8em;
  /*max-width: 125px;*/
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.labelGridItem {
  padding-inline-start: 8px;
  height: 2.5em;
  flex-basis: 0;
  flex-grow: 1;
  line-height: 2.5em;
  width: 1%;
}

.labelGridItemContainer {
  display: flex;
}

.emptyGroup {
  color: gray;
  display: flex;
  height: 20px;
  justify-content: center;
}
