.content,
.content :global(form) {
  display: flex;
  flex-direction: column;
}

.content > :not(:global(form)):not(:last-child),
.content :global(form) > :not(:last-child) {
  margin-bottom: 1em;
}

.content > :not(:global(form)):global(.MuiTypography-caption),
.content :global(form) > :global(.MuiTypography-caption) {
  margin-top: -2em;
}

.content {
  padding: 1em;
}
