.container {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1em;
}

.searchPanel {
  top: 3em;
  z-index: 10;
}

.filterButtonColor {
  color: rgb(221, 221, 221);
}

.details {
  height: 100%;
  flex-basis: calc(36em + 16px);
  overflow-y: scroll;
  width: 20em;
}

.content {
  display: flex;
  overflow-y: hidden;
  padding-top: 1em;
  flex-grow: 1;
}

.imageGrid {
  flex-basis: 100%;
  padding-right: 0.5em;
  overflow-y: scroll;
  padding-bottom: 1em;
  padding-top: 3px;
  padding-left: 3px;
  align-items: flex-start;
  align-content: flex-start;
}

.imageGrid.compact {
  flex-basis: calc(100% - 26em);
}

.loadMoreContainer {
  width: 200px;
  height: 282px;
}

.emptyGroup {
  color: rgb(128, 128, 128, 1);
  height: 100%;
  text-align: center;
  width: 100%;
}
