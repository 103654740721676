.groupInformationHeader {
  color: #069;
  border-bottom: 2px solid;
  display: inline-table;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 15px;
  padding: 12px 16px;
}

.groupInformationContainer {
  position: relative;
}

.groupInformationContainer table {
  width: 100%;
}

td {
  padding-bottom: 10px;
}
