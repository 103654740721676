.app {
  height: 100%;
  padding-left: 1em;
  padding-top: 1.5em;
  width: 20em;
}

.current {
  margin: 8px;
  padding: 8px;
}

.currentLabel {
  font-weight: bold;
  margin-inline-start: 0.5em;
}

.treeContainer {
  margin-bottom: 10px;
  max-height: 222px;
  overflow: scroll;
}

.draggingSource {
  opacity: 0.3;
}

.dropTargetDark {
  background-color: black;
  border: 1px dotted rgba(255, 255, 255, 0.6);
}

.dropTargetLight {
  background-color: lightgray;
  border: 1px dotted rgba(0, 0, 0, 0.8);
}

ul {
  list-style-type: none;
  margin-top: 0;
  padding: 0;
}
