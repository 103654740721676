.drawer {
  width: var(--global-sidebar-width);
  flex-shrink: 0;
}

.drawerPaper {
  width: var(--global-sidebar-width);
}

.content {
  padding-left: 1em;
}

.drawer .submenu {
  padding-left: 2em;
}

.optionalMenuItems {
  overflow-y: auto;
}

.clipbookName {
  font-size: 0.8em;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.clipbookButton {
  padding: 0 0 0 2em;
}
