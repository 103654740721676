.modalContainer:global(.MuiGrid-container) {
  height: 100%;
}

.modalContent {
  display: flex;
  flex-direction: row;
}

.picker {
  margin-right: 0.5em;
  display: inline-block;
}

.modalPaper {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  max-width: 30vw;
  overflow: hidden;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  width: 20em;
}

.tagPreview {
  width: fit-content;
  margin: 0 auto;
}

.modalInput {
  width: 100%;
}

.modalPaper .modalButton {
  float: right;
  margin-left: 0.5em;
}
