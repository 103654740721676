.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.container .card {
  margin: 0.1em;
}

.highlight {
  border: 2px solid #53868f;
  animation: highlight 1s ease-in-out;
}

.selected {
  border: 2px solid #e75125;
  animation: highlight 1s ease-in-out;
}

.actionRow {
  display: flex;
  flex-direction: row;
  /* just want 'Leverans klart' to be on the left side */
  justify-content: flex-end;
  margin: 0.5em 0;
}
