.event {
  padding: 0.3em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.eventHeader {
  flex: 0 1 auto;
}

.iconArea {
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eventBody {
  height: 20%;
  overflow: hidden;
  flex: 1 1 auto;
  mask-image: linear-gradient(180deg, black 70%, transparent 100%);
  -webkit-background-clip: text;
  background-clip: text;
}

.eventFooter {
  flex: 0 1 auto;
  height: fit-content;
  padding: 0.25em;
}
