.filterContainer {
  width: fit-content;
  margin: auto;
}

.filter {
  display: inline-flex;
}

.filter .selectedUserTag div {
  float: none;
}

.calendarWrapper {
  height: 100%;
  position: relative;
}

.calendarWrapper .calendar {
  height: 100%;
}

.calendarWrapper .overlay {
  z-index: 65565;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pendingChangesContainer {
  padding-top: 0.5em;
  height: 50px;
  align-content: center;
  display: inline;
}

.pendingChangesContainer .iconContainer {
  width: fit-content;
  height: fit-content;
  padding-top: 0.4em;
}

.pendingChangesContainer .buttonContainer {
  width: fit-content;
  height: fit-content;
}

.preview {
  width: 480px;
  padding: 5em 1em 1em 1em;
}
