.button:disabled {
  cursor: not-allowed;
  opacity: 0.1;
  pointer-events: auto;
}

.mediaCardContainer {
  position: relative;
}

.mediaCardOverlay {
  background: gray;
  height: 100%;
  opacity: 0.4;
  position: absolute;
  top: 0;
  width: 100%;
}

.mediaCardOverlayText {
  color: red;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 100%;
  z-index: 100;
}
