.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.addTags {
  display: flex;
  float: right;
  width: fit-content;
}

.create {
  margin-right: 0.5em;
}
