.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container > *:not(:first-child) {
  margin-top: 0.5em;
}

.container .progress {
  position: relative;
  display: inline-flex;
}

.container .progress .top {
  position: absolute;
  left: 0;
}

.container .progress .text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
