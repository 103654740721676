.timestamp {
  width: 100%;
  display: inline;
}

.commentContent {
  white-space: pre-line;
  word-wrap: break-word;
}

.action {
  margin-top: 1em;
}

.buttons {
  width: auto;
  display: block;
  margin-top: 1em;
}

.buttons .save {
  float: right;
}

.deleteButtonContainer {
  margin-top: 0.75em;
  height: 100%;
}
