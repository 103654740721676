.container {
  max-height: 60vh;
}

.container > * {
  height: 100%;
}

.contentArea {
  max-height: 100%;
  width: 500px;
}

.selectedImage {
  max-height: 40vh;
  max-width: 100%;
  object-fit: contain;
}

.versionList {
  width: 350px;
  padding: 1em;
  height: auto;
  overflow-y: auto;
}

.card {
  cursor: pointer !important;
  min-height: 5rem;
  max-height: 5rem;
  display: flex;
  width: 100%;
}

.cardInfo {
  padding-left: 0.5rem;
}

.versionLabel {
  cursor: pointer;
}

.versionWarning {
  padding: 0 2px;
  border-radius: 4px;
  border: 2px solid #c2a925;
}

.media {
  width: 5rem;
  height: 5rem;
}
