.dialog img {
  padding: 1em;
  object-fit: scale-down;
  max-width: 100vw;
  max-height: 100vh;
  width: auto;
  height: auto;
  margin: 0 auto;
}

.dialog video {
  height: 100%;
}

.toolbar {
  position: absolute;
  cursor: pointer;
  z-index: 1;
}
