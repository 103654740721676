.container {
  display: flex;
}

.container > :not(:last-child) {
  margin-right: 1em;
}

.container > * {
  flex-grow: 1;
}
