.container {
  width: 80vw;
  height: 70vh;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.articleGrid {
  margin: 0;
  flex-basis: calc(100% - 15em - 1em);
}

.m3ArticleCardInfoText {
  font-size: small;
}

.cardFooter {
  font-size: xx-small;
}

.loadMoreContainer {
  width: 200px;
  height: 282px;
}

.loadMoreContainer > * {
  margin: auto;
}

.queryPanel {
  position: sticky;
  top: 0;
  padding: 0.5em;
  flex-basis: calc(15em);
  overflow-y: auto;
}
