.group {
  border: 1px solid;
  border-radius: 8px;
  position: relative;
  background-color: inherit;
  padding: 0 4px 0 4px;
}

.input {
  font-size: 14px;
  padding: 10px 10px 10px 5px;
  border: none;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 8px;
}

.label {
  position: absolute;
  pointer-events: none;
  background-color: inherit;
  border-radius: 99%;
  top: -7px;
  font-size: 10px;
  padding-right: 0.3em;
  padding-left: 0.3em;
  display: flex;
  justify-content: center;
}
