.filterContainer {
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  flex-flow: column nowrap;
}

.filterHeader {
  height: 32px;
  font-size: 1rem;
}
