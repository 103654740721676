.container .caseInfo p,
.container .caseInfo span {
  color: #888;
  font-size: smaller;
}

.titleItem {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleInput input {
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 400;
  font-size: 2.125rem;
}

.titleInput {
  border-bottom: 1px solid transparent;
}

.setAssignedUser {
  cursor: pointer;
}
