.actionRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.imageRequest {
  margin-bottom: 0.5rem;
  padding: 5px;
  flex-basis: 100%;

  display: flex;
  flex-direction: column;
}

.imageRequestRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.summary {
  display: flex;
  width: 100%;
  margin: 0;
  min-height: unset;
}

.imageRequestShapesRow {
  justify-content: flex-start;
}

.imageRequestId {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}

.imageRequestType {
  width: 10em;
}

.imageRequestRowSpacer {
  flex-grow: 1;
}

.imageRequestComments {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 6px;
  flex-grow: 1;
}

.imageRequests {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
