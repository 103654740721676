.container {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em; /* Coordinated with positive margin of the children */
  margin-top: 0.5em;
}

.container .chip {
  margin: 0.5em; /* Coordinated with negative margin of the container */
}

.container .newTag {
  margin-left: auto;
  margin-right: 1em;
  width: fit-content;
}

.container .newTag .create {
  margin-left: 0.5em;
}

.container .create input {
  font-size: 0.8125rem;
  margin-top: 2px;
}
