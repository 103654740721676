.container {
  grid-column: 1 / 4;
  display: grid;
  grid-template-rows:
    5em
    auto;
  grid-template-columns:
    2em
    auto
    2em;
}

.searchPanel {
  grid-column: 2;
  width: 25%;
  padding: 1em;
  margin-top: 0.5em;
}

.boardContainer {
  grid-column: 1 / 4;
  width: calc(100vw - var(--global-sidebar-width));
  padding-bottom: 1em;
  padding-left: 2em;
  overflow-x: scroll;
  height: auto;
}

.preview {
  width: 480px;
  padding: 5em 1em 1em 1em;
}

.lane .dropzone {
  width: 100%;
  height: 100%;
  min-height: 1px;
}

.lane .dropzone > * {
  margin-bottom: 0.5em;
}
