.divider:global(.MuiDivider-root) {
  margin-bottom: 1em;
}

.buttonContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.buttonContainer :global(button:not(:last-child)) {
  margin-right: 1em;
}
