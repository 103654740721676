.container {
  width: 200px;
  min-height: 270px;
  height: 100%;
  padding: 0.5em;
}

.item {
  width: 100%;
  height: 100%;
}
