.thumb {
  cursor: zoom-in;
  width: 3em;
  height: 3em;
  position: relative;
}

.thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.playIcon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
