.chip {
  float: left;
  margin: 0.2em;
}

.color {
  border-radius: 50%;
}

.color:global(.MuiBox-root.MuiChip-avatarSmall) {
  width: 10px;
  height: 10px;
  margin-left: 8px;
  margin-right: -2px;
}
