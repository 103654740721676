.anchor {
  position: relative;
}

.badge {
  border-radius: 16px;
  padding: 0 0.5em;
  position: absolute;
  right: 1em;
  top: 2.5em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5;
  cursor: default;
}

button.badge {
  outline: none;
  border: none;
  cursor: pointer;
}

.faded {
  opacity: 0.6;
}
