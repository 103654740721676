.searchPanel {
  display: flex;
  justify-content: space-between;
  padding: 1em;

  top: 0;
  position: sticky;
}

.searchPanel .row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gap {
  gap: 2rem;
}

.zeroMargin {
  margin: 0;
}

.searchPanel .query {
  width: 20em;
}

.sortButton {
  width: 15em;
}
