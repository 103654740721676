.container {
  padding-top: 1.5em;
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - var(--global-sidebar-width) - 2em - 2em); /* 2em is the margin supplied to the main grid */
}

.container :global(.MuiTableRow-root) {
  height: 40px;
}

.container .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.container .paper {
  padding: 1em;
  position: relative;
}

.container .settingsRow {
  position: absolute;
  top: 0;
  right: 0;
}

.container .settingsRow .settingsButton {
  transform: scale(0.8);
}

.container .settingsRow > * {
  margin-left: auto;
}

.container .noWrap {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.container .caseRow {
  cursor: pointer;
}

.chips {
  display: flex;
  flex-wrap: wrap;
}

.chips > div {
  margin: 0.1em;
}

.loadMoreContainer {
  padding: 0 !important;
}

.loadMoreContainer > * {
  cursor: pointer;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
