.card .infoWrapper {
  padding: 0.5em;
  height: 50px;
}

.infoWrapper > :global(.MuiTypography-root) {
  font-size: x-small;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  /* Clamp body text to 4 lines */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
