.buttons {
  float: right;
}

.newTag {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  margin-top: 1em;
}

.search {
  padding-bottom: 1em;
  min-width: 15em;
  width: 25%;
}
