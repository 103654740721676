.accordion {
  box-shadow: none;
  margin: 5px -0.5rem !important;
}

.accordionSummary {
  height: 3rem;
}

.caseSectionTitle {
  font-weight: bold;
  line-height: 3rem;
  margin-right: 1rem;
}

.caseSectionDescription {
  line-height: 3rem;
  font-size: 0.6em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.caseChildren {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.titleComponent {
  margin-left: auto;
  margin-top: 5px;
}
