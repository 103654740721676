.searchListItem {
  height: 100%;
  width: 100%;
  padding-left: 1em;
}

.description {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  white-space: pre-line;
}

.tags {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.link {
  text-decoration: none;
  flex-grow: 1;
}

.videoIcon {
  float: right;
}
