.heading {
  text-transform: uppercase;
}

.divider {
  margin-top: 1em;
  margin-bottom: 1em;
}

.expansion:global(.MuiAccordion-root)::before {
  display: none;
}

.expansion :global(.MuiAccordionSummary-content) {
  margin-top: initial;
  margin-bottom: initial;
}

.expansion :global(.MuiAccordionSummary-root) {
  min-height: 0;
  padding: 0;
}

.expansion :global(.MuiAccordionDetails-root) {
  padding: 1em 0 0;
}

.expansion:global(.MuiAccordion-root.Mui-expanded),
.expansion :global(.MuiAccordionSummary-content.Mui-expanded) {
  margin: 0;
}
