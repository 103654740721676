.container {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1em;
  min-width: 20em;
}

.loading {
  position: absolute;
  margin-left: 38px;
}
