.container {
  display: flex;
  width: 100%;
  margin-top: 0.25em;
}

.container .searchPanel {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 35%;
  height: 100%;
  margin-right: 1em;
  overflow-y: scroll;
}

.searchPanel .header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 1em;
}

.search {
  padding-top: 1em;
  padding-bottom: 1em;
  width: 100%;
}

.container .calendar {
  width: 100%;
  overflow-y: scroll;
}
