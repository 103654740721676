.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  width: 100%;
}

.footer {
  flex-shrink: 0;
}

.header {
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body {
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 0.5em;
}

.buttons {
  display: flex;
  align-items: center;
}
