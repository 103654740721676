.container {
  min-height: 100vh;
  grid-row: 1 / 6;
  grid-column: 1 / 4;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.image {
  background-image: url('./../../images/login-background.jpg');
  background-size: cover;
  background-position: center;
  visibility: hidden;
}

@media (min-width: 25em) {
  .image {
    visibility: visible;
    width: 50%;
  }
}

form.controls {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 30em;
  margin: 0 auto;
  padding: 2em;
}

form.controls Button {
  margin-top: 1em;
}
