.imageInformation {
  /* max-height: 80vh; */
}

.imageInformation > * {
  /* padding: 1rem; */
  margin-bottom: 2rem;
}

.zeroMargin {
  margin: 0;
}
