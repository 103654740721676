.multiline {
  white-space: pre-wrap;
}
.table {
  position: relative;
}

.table .row:last-of-type :global(.MuiTableCell-root) {
  border-bottom: 0;
}

.table .row .actions {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.field {
  width: 100%;
}

.spacer {
  flex-grow: 1;
}

.shortField {
  width: 3em;
}

.rowInner {
  padding: 0;
  padding-top: 1em;
}

.onTopRightCorner {
  position: absolute;
  top: 0;
  right: 0;
}

.avatar {
  border-radius: 0.2em;
}

.articleHeader {
  font-weight: bold;
  margin-right: 2em;
}

.articleHeaderSeparator {
  color: rgba(0, 0, 0, 0.4);
  margin-right: 0.5em;
}

.articleCard {
  border: 1px solid var(--mp-theme-border-color);
  border-radius: 4px;
  max-width: 27em;
  width: 100%;
}

.articles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.5em;
}

.fullWidthArticle {
  flex-basis: 100%;
}

.article {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  min-height: 10em;
}

.article .articleData {
  flex-grow: 1;
  border-bottom: solid 1px var(--mp-theme-border-color);
  padding: 10px;
}

.article .articleOverviewEdit {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-right: 1px solid var(--mp-theme-border-color);
}

.article .articleEditRow {
  display: flex;
  flex-direction: row;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
}

.article .articleImage {
  width: 14em;
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.article .articleQuantity {
  flex-grow: 1;
  font-weight: bold;
}

.article .articleImage img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  bottom: 0;
}
