.thumbnailWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 160px;
}

.thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.thumbnail img {
  max-width: 100%;
  max-height: 25vh;
  object-fit: contain;
  cursor: zoom-in;
}

.productNameRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1em;
}