.drawer :global(.MuiDrawer-paper) {
  z-index: inherit;
}

.pendingChangesContainer {
  height: 64px;
}

.wrapper {
  margin-top: -20px;
  top: 50%;
  position: relative;
  width: fit-content;
  height: fit-content;
}

.wrapper .button {
  width: 100%;
}

.wrapper .loading {
  position: absolute;
  left: 50%;
  top: 50%;

  /* Margins should be half the size of the spinner */
  margin-top: -15px;
  margin-left: -15px;
}
