.item {
  height: 100%;
  width: 100%;
}

.dragging:global(.MuiPaper-root) {
  color: #2484ff;
  background-color: #deebff;
  border-color: #2484ff;
}

.wrapper {
  all: inherit;
}
